// components/LoadingScreen.js
import React, { useState, useEffect } from 'react';
import loading from '../../../assets/images/loadingscreen.jpg';

const quotes = [
  "Get ready for the ultimate poker experience!",
  "The best hands are yet to come!",
  "Prepare yourself for intense gameplay!"
];

const LoadingScreen = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const quoteInterval = setInterval(() => {
      setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
    }, 2000); 

    return () => clearInterval(quoteInterval);
  }, []);

  return (
    <div className="relative flex items-center justify-center h-screen bg-gray-800 overflow-hidden">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src={loading}
          alt="Loading Background"
          className="w-full h-full object-cover object-center md:object-cover opacity-80"
          style={{ minHeight: '100vh' }}
        />
      </div>

      {/* Loading Spinner */}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="relative w-24 h-24">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-24 h-24 border-t-4 border-green-500 border-solid rounded-full animate-spin"></div>
          </div>
        </div>
      </div>

      {/* Quote Display */}
      <div className="absolute bottom-1/3 w-full flex items-center justify-center px-4">
        <div className="text-white text-2xl font-bold text-center bg-gray-900 bg-opacity-75 p-4 rounded-lg shadow-lg">
          <p>{quote}</p>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
