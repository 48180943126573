import React from 'react';
import './gamerules.css';
import logo from '../../assets/images/black-logo.png';
import { Link } from 'react-router-dom';

const GameRulePage = () => {
  return (
    <div className="game-rule">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="My Little River Logo" />
        </div>
        <nav className="navigation">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/game-rules">Game Rules</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/community">Community</Link></li>
            <li><Link to="/login">Login</Link></li>
          </ul>
        </nav>
      </header>

      <section className="rules-section">
        <h1>Rules of My Little River Card Ranking</h1>
        <div className="rules-content">
          <h2>Game Mechanics:</h2>
          <ul>
            <li>Each player starts with 30 chips.</li>
            <li>The game accommodates up to 4 players.</li>
            <li>Players are eliminated when they lose all their chips.</li>
            <li>The last player standing wins all other players’ chips, earning 1 point per eliminated player in the first round, up to 4 points in subsequent rounds (then resets to 1 point).</li>
          </ul>

          <h2>Objective:</h2>
          <ul>
            <li>The goal is to be the first to reach 100 points.</li>
            <li>Losers lose points: -1 point for the first lost round, up to -4 points for the fourth lost round.</li>
          </ul>

          <h2>Educational and Competitive Elements:</h2>
          <ul>
            <li>Reducing the chips to 30 per person keeps the game dynamic and aids learning.</li>
            <li>Average game duration is 10 minutes, facilitating quick and effective learning.</li>
          </ul>

          <h2>Pre-Recorded Games:</h2>
          <ul>
            <li>5000 pre-recorded and numbered games will be created.</li>
            <li>All tables and players will play the same games in random order.</li>
            <li>This eliminates luck, allowing for a genuine ranking and assessment of player skill progression.</li>
          </ul>

          <h2>Analysis and Ranking:</h2>
          <ul>
            <li>Chronological summary tables and point tallies throughout the game provide valuable insights for player improvement.</li>
            <li>Some games will be specially designed as puzzles, while others will be straightforward. Winners will also be noted.</li>
          </ul>

          <h2>Community and Fun Content:</h2>
          <ul>
            <li>Cross-table rankings add extra interest.</li>
            <li>Player progress updates will foster a sense of community.</li>
          </ul>
        </div>
      </section>

      <footer className="footer">
        <div className="quick-links">
          <ul>
            <li><a href="#privacy-policy">Privacy Policy</a></li>
            <li><a href="#terms">Terms of Service</a></li>
            <li><a href="#contact-us">Contact Us</a></li>
          </ul>
        </div>
        <div className="social-icons">
          <a href="#facebook"><i className="fab fa-facebook"></i></a>
          <a href="#twitter"><i className="fab fa-twitter"></i></a>
          <a href="#instagram"><i className="fab fa-instagram"></i></a>
        </div>
        <div className="contact-info">
          <p>Email: info@mylittleriver.com</p>
          <p>Phone: +123-456-7890</p>
        </div>
      </footer>
    </div>
  );
}

export default GameRulePage;
