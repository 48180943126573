// websocket.js
import { updateGameState, playerJoined } from '../redux/gameSlice';
import io from 'socket.io-client';

let socket;

export const connectToGame = (gameId, dispatch) => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.error('No token found in localStorage');
    return;
  }

  // Establish the Socket.IO connection to the game namespace
  socket = io('http://localhost:4000/game', {
    auth: {
      token,
    },
  });

  socket.on('connect', () => {
    socket.emit('join_game', { game_id: gameId });
  });

  // Listen for game-related events and update the Redux store
  socket.on('game_info', (gameInfo) => {
    dispatch(updateGameState(gameInfo));
  });

  socket.on('players_in_game', ({ players }) => {
    // Handle player information, update Redux if necessary
    console.log('Players in game:', players);
  });

  socket.on('player_joined', (playerData) => {
    dispatch(playerJoined(playerData)); // Dispatch player joined action
    console.log('A new player has joined:', playerData);
  });

  // Handle game starting event
  socket.on('game_starting', (message) => {
    console.log('Game is starting:', message);
  });

  // Handle disconnect event
  socket.on('disconnect', () => {
    console.log('Disconnected from the game namespace');
  });

  // Handle error events
  socket.on('error', (error) => {
    console.error('WebSocket Error: ', error);
  });
};

export const sendPlayerAction = (action) => {
  if (!socket) {
    console.error('WebSocket connection not established');
    return;
  }

  socket.emit('player_action', action);
};
