import React, { useState } from 'react';
import { FaBell, FaShoppingCart, FaTrophy, FaQuestionCircle } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';
import logo from '../../assets/images/black-logo.png';
import { logout } from '../../redux/features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Header = () => {
  const profilePic = localStorage.getItem('profilePic') || 'default-avatar.png';
  const balance = localStorage.getItem('balance') || '0';
  const username = localStorage.getItem('user') || 'Guest';
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleProfileClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  // Navigate to dashboard when logo is clicked
  const handleLogoClick = () => {
    navigate('/dashboard');
  };

  return (
    <header className="bg-[#005900] text-white flex items-center justify-between p-4 shadow-md">
      {/* Logo */}
      <div className="flex items-center">
        <img
          src={logo}
          alt="Logo"
          className="w-12 h-12 cursor-pointer"
          onClick={handleLogoClick} // Add the onClick event here
        />
      </div>

      <div className="flex items-center space-x-4 md:space-x-6 lg:space-x-8">
        {/* Help Support Icon */}
        <button aria-label="Help Support" className="text-white hover:text-gray-300">
          <FaQuestionCircle size={20} />
        </button>

        {/* Notifications Icon */}
        <button aria-label="Notifications" className="text-white hover:text-gray-300">
          <FaBell size={20} />
        </button>

        {/* Shop Icon */}
        <button aria-label="Shop" className="text-white hover:text-gray-300">
          <FaShoppingCart size={20} />
        </button>

        {/* Ranking Icon */}
        <button aria-label="Ranking" className="text-white hover:text-gray-300">
          <FaTrophy size={20} />
        </button>

        {/* Username */}
        <div className="hidden md:block text-lg font-semibold">{username}</div>

        {/* Account Balance */}
        <div className="hidden md:block text-lg font-semibold">${balance}</div>

        {/* Profile Avatar with Dropdown */}
        <div className="relative">
          <button onClick={handleProfileClick} className="flex items-center space-x-2 focus:outline-none">
            <img
              src={profilePic}
              alt="Profile Avatar"
              className="w-10 h-10 rounded-full border-2 border-white object-cover"
            />
            <MdArrowDropDown size={24} />
          </button>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg text-gray-800 z-50">
              <a href="/edit-profile" className="block px-4 py-2 hover:bg-gray-200">Edit Profile</a>
              <a href="/settings" className="block px-4 py-2 hover:bg-gray-200">Settings</a>
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 hover:bg-gray-200"
              >
                Logout
              </button>            
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
