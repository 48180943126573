import React from 'react';
import card1 from '../../../assets/images/card1.png';
import card2 from '../../../assets/images/card2.png';

const PlayerDisplay = ({ player }) => {
    const positionStyles = {
        bottom: 'bottom-10',
        top: 'top-10',
        left: 'left-10',
        right: 'right-10',
    };

    return (
        <div className={`flex flex-col items-center justify-center absolute ${positionStyles[player.position]}`}>
            <div className="flex space-x-4 mb-0">
                {[card1, card2].map((card, index) => (
                    <img key={index} src={card} alt={`Card ${index + 1}`} style={{ width: 60, height: 80 }} />
                ))}
            </div>
            <div className="bg-black text-white rounded-md px-12 py-1 flex items-center justify-between w-full max-w-xs">
                <p>{player.name}</p>
                <p>{player.score}</p>
            </div>
            <img
                src={player.photoUrl}
                alt={player.name}
                style={{ width: 80, height: 80, borderRadius: '50%', marginTop: 8 }}
            />
        </div>
    );
};

export default PlayerDisplay;
