// redux/gameSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  gameInfo: null,
  totalPlayers: 0,
  status: 'waiting',
  currentRound: 0,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGameState(state, action) {
      state.gameInfo = action.payload;
      state.totalPlayers = action.payload.current_players;
      state.status = action.payload.status;
      state.currentRound = action.payload.current_round;
      state.isLoading = false; 
    },
    playerJoined(state, action) {
      state.totalPlayers += 1; 
      state.status = 'in_progress';
    },
  },
});

export const { updateGameState, playerJoined } = gameSlice.actions;
export default gameSlice.reducer;
