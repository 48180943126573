import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/axiosInstane';
import Header from '../../components/header/Header';

// Import images for hand combinations
import royalFlushImg from '../../assets/images/royal_flush.png';
import straightFlushImg from '../../assets/images/straight_flush.png';
import fourOfAKindImg from '../../assets/images/four_of_a_kind.png';
import fullHouseImg from '../../assets/images/full_house.png';
import flushImg from '../../assets/images/flush.png';
import straightImg from '../../assets/images/straight.png';
import threeOfAKindImg from '../../assets/images/three_of_a_kind.png';
import twoPairImg from '../../assets/images/two_pair.png';
import onePairImg from '../../assets/images/one_pair.png';
import highCardImg from '../../assets/images/high_card.png';

const handCombinations = [
    { id: 1, name: 'Royal Flush', price: 100, img: royalFlushImg },
    { id: 2, name: 'Straight Flush', price: 80, img: straightFlushImg },
    { id: 3, name: 'Four of a Kind', price: 70, img: fourOfAKindImg },
    { id: 4, name: 'Full House', price: 60, img: fullHouseImg },
    { id: 5, name: 'Flush', price: 50, img: flushImg },
    { id: 6, name: 'Straight', price: 40, img: straightImg },
    { id: 7, name: 'Three of a Kind', price: 30, img: threeOfAKindImg },
    { id: 8, name: 'Two Pair', price: 20, img: twoPairImg },
    { id: 9, name: 'One Pair', price: 10, img: onePairImg },
    { id: 10, name: 'High Card', price: 0, img: highCardImg },
];

const CreateGame = () => {
    const [selectedCombination, setSelectedCombination] = useState(null);
    const [games, setGames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await api.get('/api/games/my-games');
                setGames(response.data);
            } catch (error) {
                console.error('Error fetching games:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchGames();
    }, []);

    const handleSelection = (combination) => {
        setSelectedCombination(combination);
    };

    const handlePaymentSubmit = () => {
        if (selectedCombination) {
            navigate('/payment', { state: { combination: selectedCombination } });
        } else {
            alert('Please select a hand combination.');
        }
    };

    const handleCreateGame = async () => {
        if (selectedCombination) {
            try {
                const token = localStorage.getItem('token');
                const response = await api.post('/api/games/create', 
                    {
                        game_name: selectedCombination.name,
                        total_players: 4,
                    }, 
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );

                if (response.status === 201) {
                    alert('Game created successfully!');
                    navigate(`/game/${response.data.game_id}`);
                } else {
                    alert('Failed to create the game.');
                }
            } catch (error) {
                console.error('Error creating the game:', error);
                alert('Error creating the game. Please try again.');
            }
        } else {
            alert('Please select a hand combination.');
        }
    };

    // Filter out hand combinations that the user has already created
    const availableCombinations = handCombinations.filter(
        (combination) => !games.some((game) => game.game_name === combination.name)
    );

    return (
        <div className="min-h-screen flex flex-col">
            <Header />

            <div className="flex-grow bg-gray-100 p-8">
                <h1 className="text-3xl font-bold text-center mb-8">Select a Hand Combination</h1>
                {isLoading ? (
                    <div className="text-center text-lg">Loading...</div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {availableCombinations.map((combination) => (
                            <div
                                key={combination.id}
                                className={`cursor-pointer p-4 rounded-lg shadow-md hover:shadow-xl ${
                                    selectedCombination && selectedCombination.id === combination.id
                                        ? 'border-4 border-green-500'
                                        : 'border-2 border-transparent'
                                }`}
                                onClick={() => handleSelection(combination)}
                            >
                                <img src={combination.img} alt={combination.name} className="w-full h-48 mb-4 object-contain" />
                                <div className="text-lg font-semibold">{combination.name}</div>
                                <div className="text-gray-600">${combination.price}</div>
                                <div className="text-gray-600">Max Players: 4</div>
                            </div>
                        ))}
                    </div>
                )}
                {selectedCombination && selectedCombination.name === 'High Card' ? (
                    <button
                        onClick={handleCreateGame}
                        className="mt-8 w-full py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
                    >
                        Create Game
                    </button>
                ) : (
                    <button
                        onClick={handlePaymentSubmit}
                        className="mt-8 w-full py-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition"
                    >
                        Proceed to Payment
                    </button>
                )}
            </div>
        </div>
    );
};

export default CreateGame;
