import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    FaCheckCircle,
    FaTimesCircle,
    FaEdit,
    FaPhone,
    FaEnvelope,
    FaUser,
    FaWhatsapp,
    FaTimes,
    FaSpinner,
} from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/axiosInstane';
import Header from '../../components/header/Header';

const Profile = () => {
    const [profile, setProfile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showPhoneModal, setShowPhoneModal] = useState(false);
    const [receiveWhatsAppNotifications, setReceiveWhatsAppNotifications] = useState(false);
    const [showVerificationCode, setShowVerificationCode] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('api/auth/profile', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setProfile(response.data);
                setReceiveWhatsAppNotifications(response.data.is_whatsapp_notification_enabled);
            } catch (error) {
                toast.error('Failed to fetch profile');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const handleUpdateUsername = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.patch(
                '/api/users/profile',
                {
                    username: newUsername,
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setProfile(response.data);
            toast.success('Username updated successfully');
            setIsEditing(false);
        } catch (error) {
            toast.error('Failed to update username');
        }
    };

    const handleWhatsAppNotificationChange = (e) => {
        setReceiveWhatsAppNotifications(e.target.checked);
    };

    const handleVerificationSubmit = () => {
        if (verificationCode.length === 5) {
            toast.success('Verification successful');
            setShowEmailModal(false);
            setShowPhoneModal(false);
            setShowVerificationCode(false);
        } else {
            toast.error('Invalid verification code');
        }
    };

    const profileCompletion = () => {
        let completion = 50; // base completion score
        if (profile && profile.username) completion += 10;
        if (profile && profile.email) completion += 10;
        if (profile && profile.is_phone_verified) completion += 20;
        if (receiveWhatsAppNotifications) completion += 10;
        return completion;
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-green-100">
                <FaSpinner className="animate-spin text-green-600 text-4xl" />
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen bg-green-100">
            <Header />
            <div className="flex-grow flex items-center justify-center p-4">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full max-w-4xl">
                    <div className="flex">
                        {/* Left Side - Profile Information */}
                        <div className="w-1/3 bg-green-600 text-white p-8">
                            <div className="flex flex-col items-center">
                                {profile && profile.profile_pic ? (
                                    <img
                                        src={profile.profile_pic}
                                        alt="Profile"
                                        className="w-24 h-24 rounded-full mb-4"
                                    />
                                ) : (
                                    <FaUser className="w-24 h-24 mb-4" />
                                )}
                                <h2 className="text-2xl font-bold">
                                    {profile?.username || 'Username'}
                                </h2>
                                <p className="text-sm mb-2">{profile?.email}</p>
                                <div className="flex items-center">
                                    <FaEnvelope />
                                    <FaCheckCircle className="text-green-500 ml-2" />
                                </div>
                                <div className="flex items-center mt-2">
                                    <FaPhone />
                                    {profile?.is_phone_verified ? (
                                        <FaCheckCircle className="text-green-500 ml-2" />
                                    ) : (
                                        <FaTimesCircle className="text-red-500 ml-2" />
                                    )}
                                </div>
                                <div className="flex items-center mt-2">
                                    <FaWhatsapp />
                                    {receiveWhatsAppNotifications ? (
                                        <FaCheckCircle className="text-green-500 ml-2" />
                                    ) : (
                                        <FaTimesCircle className="text-red-500 ml-2" />
                                    )}
                                </div>
                                <p className="mt-4">Balance: ${profile?.balance}</p>
                                <p className="text-xs mt-2">
                                    Account Created: {new Date(profile?.created_at).toLocaleDateString()}
                                </p>
                                <p className="text-xs">
                                    Last Updated: {new Date(profile?.updated_at).toLocaleDateString()}
                                </p>
                            </div>
                        </div>

                        {/* Right Side - Edit Profile Section */}
                        <div className="w-2/3 p-8">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">Edit Profile</h2>
                            <div className="mb-6">
                                <label
                                    className="block text-gray-700 font-semibold mb-2"
                                    htmlFor="username"
                                >
                                    Username
                                </label>
                                <div className="flex items-center">
                                    <input
                                        type="text"
                                        id="username"
                                        value={newUsername}
                                        onChange={(e) => setNewUsername(e.target.value)}
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        disabled={!isEditing}
                                    />
                                    {isEditing ? (
                                        <button
                                            onClick={handleUpdateUsername}
                                            className="bg-green-500 text-white font-bold py-2 px-4 ml-2 rounded focus:outline-none focus:shadow-outline hover:bg-green-700"
                                        >
                                            Update
                                        </button>
                                    ) : (
                                        <FaEdit
                                            className="text-gray-600 ml-2 cursor-pointer"
                                            onClick={() => setIsEditing(true)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="mb-6">
                                <button
                                    onClick={() => setShowEmailModal(true)}
                                    className="bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-700 transition-transform transform hover:scale-105"
                                >
                                    Edit Email
                                </button>
                            </div>
                            <div className="mb-6">
                                <button
                                    onClick={() => setShowPhoneModal(true)}
                                    className="bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-700 transition-transform transform hover:scale-105"
                                >
                                    Edit Phone Number
                                </button>
                            </div>
                            <div className="mb-6">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={receiveWhatsAppNotifications}
                                        onChange={handleWhatsAppNotificationChange}
                                        className="mr-2 leading-tight"
                                    />
                                    <span className="text-gray-700">Receive notifications via WhatsApp</span>
                                </label>
                            </div>

                            {/* Profile Completion Slider */}
                            <div className="mb-6">
                                <h3 className="text-gray-700 font-semibold mb-2">Profile Completion</h3>
                                <div className="w-full bg-gray-200 rounded-full">
                                    <div
                                        className="bg-green-600 text-xs font-medium text-white text-center p-0.5 leading-none rounded-full"
                                        style={{ width: `${profileCompletion()}%` }}
                                    >
                                        {profileCompletion()}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Email Modal */}
            {showEmailModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                        <FaTimes
                            className="absolute top-4 right-4 text-gray-600 cursor-pointer"
                            onClick={() => setShowEmailModal(false)}
                        />
                        <h2 className="text-xl font-bold mb-4">Edit Email</h2>
                        <input
                            type="email"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                            placeholder="Enter new email"
                        />
                        <button
                            className="bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-700 w-full"
                            onClick={() => setShowVerificationCode(true)}
                        >
                            Save Email
                        </button>
                    </div>
                </div>
            )}

            {/* Phone Modal */}
            {showPhoneModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                        <FaTimes
                            className="absolute top-4 right-4 text-gray-600 cursor-pointer"
                            onClick={() => setShowPhoneModal(false)}
                        />
                        <h2 className="text-xl font-bold mb-4">Edit Phone Number</h2>
                        <input
                            type="text"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                            placeholder="Enter new phone number"
                        />
                        <button
                            className="bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-700 w-full"
                            onClick={() => setShowVerificationCode(true)}
                        >
                            Save Phone Number
                        </button>
                    </div>
                </div>
            )}

            {/* Verification Modal */}
            {showVerificationCode && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
                        <FaTimes
                            className="absolute top-4 right-4 text-gray-600 cursor-pointer"
                            onClick={() => setShowVerificationCode(false)}
                        />
                        <h2 className="text-xl font-bold mb-4">Enter Verification Code</h2>
                        <input
                            type="text"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                            placeholder="Enter verification code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                        />
                        <button
                            className="bg-green-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:bg-green-700 w-full"
                            onClick={handleVerificationSubmit}
                        >
                            Verify
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Profile;
