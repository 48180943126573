import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/Footer/Footer';
import AvailableGames from './components/AvailableGames';
import Rankings from './components/Rankings';
import { FaPlusCircle, FaSearch } from 'react-icons/fa';
import api from '../../api/axiosInstane';

function Dashboard() {
  const [gameId, setGameId] = useState('');
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const rankings = {
    individual: [
      { name: 'Cyril', points: 53 },
      { name: 'Muhammed', points: 7 },
      { name: 'John', points: 0 },
      { name: 'Aniiz', points: -3 },
    ],
    team: [
      { name: 'Cyril', points: -7 },
      { name: 'Muhammed', points: 7 },
      { name: 'John', points: 0 },
      { name: 'Aniiz', points: 0 },
    ],
    general: [
      { name: 'Cyril', points: -5 },
      { name: 'Muhammed', points: 7 },
      { name: 'John', points: 0 },
      { name: 'Aniiz', points: -3 },
    ],
  };

  useEffect(() => {
    const token = localStorage.getItem('token'); // Adjust this key to match your token key in localStorage
    if (!token) {
      navigate('/login'); // Redirect to the login page if no token is found
    } else {
      const fetchGames = async () => {
        try {
          const response = await api.get('/api/games/my-games');
          setGames(response.data);
        } catch (error) {
          console.error('Error fetching games:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchGames();
    }
  }, [navigate]);

  const handleJoinGame = () => {
    console.log('Joining game with ID:', gameId);
  };

  const handleCreateGame = () => {
    navigate('/create-game');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-900 to-green-700 text-white">
      <Header />

      <div className="p-6 max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold mb-6 text-center">Welcome to the MLRC</h1>

        {/* Game Creation and Joining Section */}
        <div className="bg-gray-100 text-gray-800 rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-center">Create or Join a Game</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Create a Game */}
            <div
              onClick={handleCreateGame}
              className="bg-gradient-to-r from-green-600 to-green-400 p-6 rounded-lg shadow-lg text-center transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
            >
              <FaPlusCircle className="text-4xl text-white mb-4 mx-auto" />
              <h3 className="text-xl font-bold mb-2 text-white">Create a Game</h3>
              <p className="mb-4 text-white">Set up a new game with your preferred settings.</p>
              <button className="bg-white text-green-700 font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 ease-in-out">
                Create Game
              </button>
            </div>

            {/* Join a Game */}
            <div className="bg-gradient-to-r from-blue-600 to-blue-400 p-6 rounded-lg shadow-lg text-center transform hover:scale-105 transition duration-300 ease-in-out">
              <FaSearch className="text-4xl text-white mb-4 mx-auto" />
              <h3 className="text-xl font-bold mb-2 text-white">Join a Game</h3>
              <p className="mb-4 text-white">Enter the Game ID to join an existing game.</p>
              <input
                type="text"
                placeholder="Enter Game ID"
                value={gameId}
                onChange={(e) => setGameId(e.target.value)}
                className="mb-4 p-2 rounded-lg border border-gray-300 w-full"
              />
              <button
                onClick={handleJoinGame}
                className="bg-white text-blue-700 font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 ease-in-out"
              >
                Join Game
              </button>
            </div>
          </div>
        </div>

        {/* Available Games Section */}
        <AvailableGames games={games} isLoading={isLoading} />

        {/* Ranking Section */}
        <Rankings rankings={rankings} />
      </div>

      <Footer />
    </div>
  );
}

export default Dashboard;
