import React from 'react';
import Header from './Header';
import HeroSection from './MainBanner';
import Features from './Features';
import Footer from '../../components/Footer/Footer';
import HeroSection2 from './HeroSection';

function HomePage() {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <div className="hidden md:block">
      <div className="absolute left-1 sm:left-4 md:left-8 top-[-30px] sm:top-[-20px] md:top-[-30px] z-10">
        <img
          src={require('../../assets/images/black-logo.png')}
          alt="Logo"
          className="h-[150px] sm:h-[200px] md:h-[250px] lg:h-[300px] w-auto"
        />
      </div>
      </div>
      {/* Hero Sections */}
      <div className="hidden md:block">
        <HeroSection /> {/* Display on medium and larger screens */}
      </div>
      <div className="block md:hidden">
        <HeroSection2 />
      </div>
      <Features />
      <Footer />
    </div>
  );
}

export default HomePage;
