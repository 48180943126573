import React from 'react';
import './contact.css';
import logo from '../../assets/images/black-logo.png';
import { Link } from 'react-router-dom';

const ContactPage = () => {
  return (
    <div className="contact">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="My Little River Logo" />
        </div>
        <nav className="navigation">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/game-rules">Game Rules</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/login">Login</Link></li>

          </ul>
        </nav>
      </header>

      <section className="contact-banner">
        <div className="banner-content">
          <h1>Contact Us</h1>
          <p>We'd love to hear from you!</p>
        </div>
      </section>

      <section className="contact-content">
        <div className="contact-details">
          <h2>Get in Touch</h2>
          <p>If you have any questions or need support, please reach out to us. We're here to help!</p>
          <p>Email: <a href="mailto:info@mylittleriver.com">info@mylittleriver.com</a></p>
          <p>Phone: +91-9744586291</p>
          <p>Address: 123 Poker Street, Card City, CA 12345</p>
        </div>

        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="5" required></textarea>
            </div>
            <button type="submit" className="cta-button">Send Message</button>
          </form>
        </div>
      </section>

      <footer className="footer">
        <div className="quick-links">
          <ul>
            <li><a href="#privacy-policy">Privacy Policy</a></li>
            <li><a href="#terms">Terms of Service</a></li>
            <li><a href="#contact-us">Contact Us</a></li>
          </ul>
        </div>
        <div className="social-icons">
          <a href="#facebook"><i className="fab fa-facebook"></i></a>
          <a href="#twitter"><i className="fab fa-twitter"></i></a>
          <a href="#instagram"><i className="fab fa-instagram"></i></a>
        </div>
        <div className="contact-info">
          <p>Email: info@mylittleriver.com</p>
          <p>Phone: +123-456-7890</p>
        </div>
      </footer>
    </div>
  );
}

export default ContactPage;
