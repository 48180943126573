import React from 'react';

function Features() {
  return (
    <section className="bg-[#099241] py-16 relative overflow-hidden">
      <div className="absolute inset-0 bg-[#004d00] opacity-40 -z-10" />
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-white mb-12">
          Exciting Features
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Feature 1 */}
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-[#d1e0d1] transition-colors duration-300 ease-in-out animate__animated animate__fadeIn">
            <div className="text-center mb-4">
              <svg className="w-12 h-12 text-[#14572e] mx-auto animate__animated animate__bounceIn" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6l4 2m0 6l-4-2m4-8H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-[#14572e] mb-4">
              Create and Customize Games
            </h3>
            <p className="text-gray-800">
              Create games with customizable settings, choose between free or paid options, and invite friends to join.
            </p>
          </div>

          {/* Feature 2 - Texas Hold'em Version */}
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-[#d1e0d1] transition-colors duration-300 ease-in-out animate__animated animate__fadeIn">
            <div className="text-center mb-4">
              <svg className="w-12 h-12 text-[#14572e] mx-auto animate__animated animate__bounceIn" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-[#14572e] mb-4">
              Texas Hold'em Version
            </h3>
            <p className="text-gray-800">
              Play the popular Texas Hold'em poker with up to 4 players. Experience strategic gameplay and intense competition.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-[#d1e0d1] transition-colors duration-300 ease-in-out animate__animated animate__fadeIn">
            <div className="text-center mb-4">
              <svg className="w-12 h-12 text-[#14572e] mx-auto animate__animated animate__bounceIn" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9V5h4v4h5v4h-5v4h-4v-4H5v-4h5z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-[#14572e] mb-4">
              Flexible Game Scheduling
            </h3>
            <p className="text-gray-800">
              Set your game time and invite players at your convenience. Play based on points and resume anytime.
            </p>
          </div>

          {/* Feature 4 */}
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-[#d1e0d1] transition-colors duration-300 ease-in-out animate__animated animate__fadeIn">
            <div className="text-center mb-4">
              <svg className="w-12 h-12 text-[#14572e] mx-auto animate__animated animate__bounceIn" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h4l1 6h8l1-6h4m-9 5h-2v2h2v-2zm-4 0h-2v2h2v-2z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-[#14572e] mb-4">
              Points-Based Play
            </h3>
            <p className="text-gray-800">
              Enjoy a dynamic gaming experience where you earn and use points to strategize and compete.
            </p>
          </div>

          {/* Feature 5 */}
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-[#d1e0d1] transition-colors duration-300 ease-in-out animate__animated animate__fadeIn">
            <div className="text-center mb-4">
              <svg className="w-12 h-12 text-[#14572e] mx-auto animate__animated animate__bounceIn" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-[#14572e] mb-4">
              Continuous Gameplay
            </h3>
            <p className="text-gray-800">
              Pick up where you left off anytime, ensuring a seamless and enjoyable gaming experience.
            </p>
          </div>

          {/* Feature 6 - Community and Fun */}
          <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-2xl hover:bg-[#d1e0d1] transition-colors duration-300 ease-in-out animate__animated animate__fadeIn">
            <div className="text-center mb-4">
              <svg className="w-12 h-12 text-[#14572e] mx-auto animate__animated animate__bounceIn" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3V7a3 3 0 00-3-3H3z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-[#14572e] mb-4">
              Community and Fun
            </h3>
            <p className="text-gray-800">
              Join the community, participate in fun activities, and stay updated with all the latest information.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
