import React from 'react';
import './about.css';
import logo from '../../assets/images/black-logo.png';
import { Link } from 'react-router-dom';

const AboutUsPage = () => {
  return (
    <div className="about">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="My Little River Logo" />
        </div>
        <nav className="navigation">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/game-rules">Game Rules</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/login">Login</Link></li>
          </ul>
        </nav>
      </header>

      <section className="about-banner">
        <div className="banner-content">
          <h1>About Us</h1>
          <p>Discover the story behind My Little River Poker.</p>
        </div>
      </section>

      <section className="about-content">
        <h2>Our Mission</h2>
        <p>At My Little River Poker, our mission is to revolutionize the way you experience Texas Hold'em poker. We aim to create an engaging, educational, and fun environment for players of all skill levels.</p>
        
        <h2>Our Story</h2>
        <p>Founded in 2023, My Little River Poker was born out of a passion for poker and a desire to bring a fresh perspective to the game. Our founders, seasoned poker enthusiasts, saw an opportunity to blend traditional poker mechanics with innovative gameplay elements.</p>
        
        <h2>Our Team</h2>
        <p>Our team is comprised of dedicated professionals with a shared love for poker. From game developers to customer support, each member plays a crucial role in delivering the best possible experience for our players.</p>
        
        <h2>Join Us</h2>
        <p>Become a part of the My Little River Poker community. Whether you're a seasoned pro or a curious beginner, we welcome you to join us in this exciting journey. Sign up now and start playing!</p>
      </section>

      <footer className="footer">
        <div className="quick-links">
          <ul>
            <li><a href="#privacy-policy">Privacy Policy</a></li>
            <li><a href="#terms">Terms of Service</a></li>
            <li><a href="#contact-us">Contact Us</a></li>
          </ul>
        </div>
        <div className="social-icons">
          <a href="#facebook"><i className="fab fa-facebook"></i></a>
          <a href="#twitter"><i className="fab fa-twitter"></i></a>
          <a href="#instagram"><i className="fab fa-instagram"></i></a>
        </div>
        <div className="contact-info">
          <p>Email: info@mylittleriver.com</p>
          <p>Phone: +123-456-7890</p>
        </div>
      </footer>
    </div>
  );
}

export default AboutUsPage;
