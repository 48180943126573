import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { connectToGame } from './services/websocket';
import LoadingScreen from './components/LoadingScreen';
import PlayerDisplay from './components/PlayerDisplay';
import communtiy_card from '../../assets/images/card5.png';

const GamePage = () => {
  const { game_id } = useParams();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);

  // Dummy data for four players
  const players = [
    { name: "Cyril", score: 30, position: "bottom", photoUrl: "https://lh3.googleusercontent.com/a/ACg8ocIav40662PO-Na4hc3t7Km_H-eYeUy2e_Nc2BI131MyONL_3XE=s96-c" },
    { name: "Alex", score: 45, position: "left", photoUrl: "https://via.placeholder.com/80" },
    { name: "Sam", score: 52, position: "top", photoUrl: "https://via.placeholder.com/80" },
    { name: "Jordan", score: 28, position: "right", photoUrl: "https://via.placeholder.com/80" }
  ];

  // Pot value (this could be dynamic)
  const potValue = 150;

  useEffect(() => {
    connectToGame(game_id, dispatch);

    const loadingTimeout = setTimeout(() => {
      setShowLoading(false);
    }, 15000);

    return () => clearTimeout(loadingTimeout);
  }, [game_id, dispatch]);

  if (showLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="h-screen w-full flex flex-wrap justify-center items-center relative" style={{ backgroundColor: '#91d31a' }}>
      {/* Render player displays */}
      {players.map(player => (
        <PlayerDisplay key={player.name} player={player} />
      ))}

      {/* Community cards in the center */}
      <div className="absolute flex space-x-4" style={{ top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        {[...Array(5)].map((_, index) => (
          <img key={index} src={communtiy_card} alt={`Community card ${index + 1}`} style={{ width: 90, height: 120 }} />
        ))}
      </div>

      {/* Pot Rectangle with Pot value aligned right */}
      <div className="absolute flex items-center justify-between bg-[#35702E] text-white rounded-2xl py-3 px-6" 
           style={{ top: '55%', left: '50%', transform: 'translateX(-50%)', width: '300px', borderRadius: '30px' }}>
        <p className="text-xl font-semibold">Pot</p>
        <p className="text-2xl font-bold">{potValue}</p>
      </div>
    </div>
  );
};

export default GamePage;
